@import url(https://fonts.googleapis.com/css?family=Montserrat:300,500,700);
*, *::before, *::after {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}
.galleryButton{
    
    /* width: 20%;
    height: 20%; */
   border: none;
    background-color: inherit;
    color:#fff;
  }

  .carousel-control-next{
    right: -40px;
  }

  .carousel-control-prev{
    left: -40px;
  }
  

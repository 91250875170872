.galleryButton{
    
    /* width: 20%;
    height: 20%; */
   border: none;
    background-color: inherit;
    color:#fff;
  }

  .carousel-control-next{
    right: -40px;
  }

  .carousel-control-prev{
    left: -40px;
  }
  